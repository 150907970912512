@use "./Scss-vars/Colors.scss";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
.chakra-form__label{
    color:rgb(154, 150, 150);
    font-size: 13px !important;
    font-weight: 600;
   
}
.chakra-input{
    height: 45px !important;
}
.css-1c6j008[aria-invalid="true"] {
    border: 1px solid !important;
    border-color: inherit !important;
    box-shadow: none !important
  ;
  }

  .pending {
    color: Colors.$info;
    background-color: Colors.$info-opacity;
    padding: 0.6rem;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    text-transform: capitalize;
    cursor: default;
    &:hover {
      background-color: rgba(255, 189, 40, 0.2);
    }
  }
  
  .done {
    color: Colors.$primary;
    background-color: Colors.$primary-opacity;
    text-transform: capitalize;
    padding: 0.6rem;
    font-size: 14px;
    font-weight: bold;
    transition: 0.3s ease-in-out;
    cursor: default;
    &:hover {
      background-color: Colors.$primary-opacity-hover;
    }
  }

  .css-1cw84h2{
    left: 10px !important;
  }

  @media only screen and (max-width: 1202px) {
    .header-links{
      font-size: 14px;
    }
  }
