.dropzone {
  padding: 25px 0px;
  border: 1px dashed rgb(206, 206, 206);
  border-radius: 10px;
  // width: 75%;
  p {
    font-size: 14px; 
  }


}


// .PrivateNotchedOutline-root-9