.input-search{
    position: relative;
    input{
        position:static !important
    };
    .icon-search{ 
        font-size: 20px;
        position: absolute;
        top: 25%;
        left: 6%;
    }
  }