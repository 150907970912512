@use "../../Scss-vars/Colors.scss";

.list-wrab {
  display: flex;
  justify-content: center;
  .list {
    list-style: none;
    .list-item {
      a {
        transition: 0.2s ease-in-out;
        color: #979797;
        &:hover {
          color: Colors.$primary!important;
          background-color: Colors.$primary-opacity;
          border-left: solid 0.16rem Colors.$primary;
        }
      }
      i{
        margin-left: 10px;
      }
    }
    .list-item-dark {
      a{
        &:hover{
          color: Colors.$secondary !important;
          background-color:Colors.$secondary-opacity;
          border-left: solid 0.16rem Colors.$secondary;
        }
      }
    }
  }
}

.active-profile-link {
  color: Colors.$primary !important;
  background-color: Colors.$primary-opacity !important;
  border-left: solid 0.16rem Colors.$primary !important;
}
.active-profile-link-dark {
  color: Colors.$secondary !important;
  background-color: Colors.$secondary-opacity !important;
  border-left: solid 0.16rem Colors.$secondary !important;
}
.css-ddil6j[data-checked] {
  background: Colors.$primary !important;
}



.country{
  position: relative;
  span{
    content: "<i class='fas fa-angle-down'></i>";
    position: absolute;
    top: 25%;
    right: 5%;
    font-size: 15px;
    padding: 0;
  }
}
.fw-primary {
  font-weight: 500;
  font-size: 15px;
  color: Colors.$primary!important;
}
.disabled-pic{
  opacity: .5;
  pointer-events: none;
  &::after{
    content: "Please waite...";
    position: absolute;
    font-size: 13px;
    left: 15%;
  }
}

.input-drop-down-phone .country span {
  position: static !important;

}
.input-drop-down-dark .country{
  background-color: transparent !important;
}

.input-drop-down-dark .country:hover{
  background-color: rgb(181,181,181) !important;
}

.input-drop-down-button-dark:hover{
  background-color: rgb(72,72,72) !important;
}

.input-drop-down-search{
  z-index: 50;
}